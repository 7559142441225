import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import hero_about from "../assets/hero_about.svg"
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import MisionVision from "../components/MisionVision";
import { useEffect } from "react";

function About (){
    useEffect(() => {
        document.title = "Dinarac | About";
    },[]);
    return(
        <>
            <Navbar/>
            <Hero
            cName='hero_small'
            heroImg={hero_about}
            picSize='hero_pic_small'
            title="About dinarac"
            btnClass="hide"
            />

            <MisionVision/>

            <CTA/>

            <Footer/>
        </>
    )
}

export default About;