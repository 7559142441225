import "./ApplicationsStyles.css"
import pic_aerospace from "../assets/applications/aerospace.svg"
import pic_automotive from "../assets/applications/automotive.svg"
import pic_construction from "../assets/applications/construction.svg"
import pic_electronics from "../assets/applications/electronics.svg"
import pic_energy from "../assets/applications/energy.svg"
import pic_hvac from "../assets/applications/hvac.svg"
import pic_marine from "../assets/applications/marine.svg"
import pic_process from "../assets/applications/process.svg"

const Applications = () => {
    return(
        <div className="applications">
            <h2>Applications</h2>
            <div className="applications_all">
                <div className="application1">
                    <div className="picture_application">
                        <img alt='image' src={pic_aerospace} />
                    </div>
                    <div className="text_aplication">
                        <h4>Aerospace</h4>
                    </div>
                </div>
                <div className="application2">
                    <div className="picture_application">
                        <img alt='image' src={pic_automotive} />
                    </div>
                    <div className="text_aplication">
                        <h4>Automotive</h4>
                    </div>
                </div>
                <div className="application2">
                    <div className="picture_application">
                        <img alt='image' src={pic_construction} />
                    </div>
                    <div className="text_aplication">
                        <h4>Construction</h4>
                    </div>
                </div>
                <div className="application1">
                    <div className="picture_application">
                        <img alt='image' src={pic_electronics} />
                    </div>
                    <div className="text_aplication">
                        <h4>Electronics</h4>
                    </div>
                </div>
                <div className="application1">
                    <div className="picture_application">
                        <img alt='image' src={pic_energy} />
                    </div>
                    <div className="text_aplication">
                        <h4>Energy</h4>
                    </div>
                </div>
                <div className="application2">
                    <div className="picture_application">
                        <img alt='image' src={pic_hvac} />
                    </div>
                    <div className="text_aplication">
                        <h4>HVAC</h4>
                    </div>
                </div>
                <div className="application1">
                    <div className="picture_application">
                        <img alt='image' src={pic_marine} />
                    </div>
                    <div className="text_aplication">
                        <h4>Marine</h4>
                    </div>
                </div>
                <div className="application2">
                    <div className="picture_application">
                        <img alt='image' src={pic_process} />
                    </div>
                    <div className="text_aplication">
                        <h4>Process</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Applications