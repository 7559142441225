import "./FooterStyles.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXTwitter, faYoutubeSquare, faGithubSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import logo_white from "../assets/LOGO_white.svg"

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer_info">
                <div className="logo_footer">
                    <img alt="image" src={logo_white} />
                </div>
                <div className="coloumn_footer">
                    <h4>Website Map</h4>
                    <a href="/">Home</a>
                    <a href="/about">About</a>
                    <a href="/solutions">Solutions</a>
                    <a href="/blog">Blog</a>
                </div>
                <div className="coloumn_footer">
                    <h4>Contact Us</h4>
                    <div className="contact_footer">
                        <p><i class="fa-solid fa-envelope"></i> office@dinarac.com</p>
                    </div>
                </div>
                <div className="social_footer">
                    <a href="https://www.youtube.com/@dinarac_com" target="_blank">
                        <FontAwesomeIcon className="font_icon" icon={faYoutubeSquare} />
                    </a>
                    <a href="https://github.com/dinarac" target="_blank">
                        <FontAwesomeIcon className="font_icon" icon={faGithubSquare} />
                    </a>
                    <a href="https://www.linkedin.com/company/dinarac-com/" target="_blank">
                        <FontAwesomeIcon className="font_icon" icon={faLinkedin} />
                    </a>
                    <a href="https://twitter.com/dinarac_com" target="_blank">
                        <FontAwesomeIcon className="font_icon" icon={faSquareXTwitter} />
                    </a>
                </div>
            </div>
            <div className="footer_copyright">
                <p>Copyright&nbsp;<i class="fa-regular fa-copyright"></i>&nbsp;2024 Dinarac. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer