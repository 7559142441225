import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import hero_blog from "../assets/hero_blog.svg"
import Footer from "../components/Footer";
import Blogs from "../components/Blogs";
import { useEffect } from "react";

function Blog (){
    useEffect(() => {
        document.title = "Dinarac | Blog";
    },[]);
    return(
        <>
            <Navbar/>
            <Hero
            cName='hero_small'
            heroImg={hero_blog}
            picSize='hero_pic_small'
            title="Blog"
            btnClass="hide"
            />

            <Blogs />

            <Footer/>
        </>
    )
}

export default Blog;