import "./BlogsStyles.css"
import blog1 from '../assets/blog/blog1.jpg';
import blog2 from '../assets/blog/blog2.jpg';
import blog3 from '../assets/blog/blog3.jpg';
import blog4 from '../assets/blog/blog4.jpg';

function BlogsData(props) {
    return(
        <div className="blog_card">
            <a href={props.url} className="blog_url" target="_blank">
            <div className="blog_pic">
                <img src={props.image} alt="image" />
            </div>
            <h3>{props.heading}</h3>
            <p>{props.text}</p>
            </a>
        </div>
    )
}

const Blogs = () => {
    return(
        <div className="blogs">
            <h2>Video library</h2>

            <div className="blogs_card">
                <BlogsData 
                    image={blog4}
                    url="https://www.youtube.com/watch?v=tSQ6CqH0sJo"
                    heading='Propeller Simulation using AMI'
                    text='At Dinarac, our pursuit of excellence in computational fluid dynamics leads us to dive deep into the world of marine 
                    engineering. In our latest investigation, we harness the capabilities of OpenFOAMs Advanced Meshing Interface (AMI) rotational
                    zone to shed light on the dynamics of a submarine propeller in motion. In comparison to the conventional MRF approach,
                    our simulation methodology unveils a more understanding of fluid-structure interactions and propeller dynamics, offering
                    deeper insights into marine engineering.'
                />


                <BlogsData 
                    image={blog3}
                    url="https://www.youtube.com/watch?v=zmaaO1eNDzA"
                    heading='Valve Opening and Closing Simulation with Moving Mesh'
                    text='Employing advanced moving mesh simulations within OpenFOAM, our study delves into the sophisticated behavior of a valves
                    rhythmic opening and closing. This investigation unveils intricate fluid dynamics, shedding light on pressure
                    differentials, turbulent flows, and fluid-structure interactions. This data-rich exploration not only captivates the eye but
                    also informs engineering decisions, exemplifying the power of computational fluid dynamics in optimizing designs and shaping
                    real-world applications.'
                />

                <BlogsData 
                    image={blog2}
                    url="https://www.youtube.com/watch?v=qs94ODLqYt0"
                    heading='Moving Cylinder with Overset Mesh Application'
                    text='Exploring fluid dynamics, we embarked on a captivating 2D simulation journey centered around a moving cylinder. Applying
                    overset mesh principles, we modeled the cylinders sinusoidal motion. Our simulation captured fluid interactions, revealing insights
                    into flow patterns and forces. Through innovative overset mesh, we showcased fluid behavior complexities around a dynamic object,
                    transforming our understanding of fluid dynamics.'
                />

                <BlogsData 
                    image={blog1}
                    url="https://www.youtube.com/watch?v=HogodxtV1qY"
                    heading='CFD of Iron Casted vs. Aluminium Radiator'
                    text='In our latest study, we compared the performance of an old iron casted radiator and a modern aluminum counterpart
                    under identical conditions. Applying a consistent pressure difference and inlet water temperature, we employed a Conjugate Heat
                    Transfer (CHT) solver to analyze temperature variations. The results, showcased in a detailed video on our YouTube channel,
                    shed light on the superior heat dissipation and efficiency of the aluminum radiator, showcasing its potential to revolutionize
                    heat exchange in various applications.'
                />
            </div>

        </div>
    )
}

export default Blogs;