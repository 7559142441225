import './HeroStyles.css';

function Hero (props){

    return(
        <>
            <div className={props.cName}>

                <div className='hero_text_pic'>

                    <div className="hero_text">
                        <h1>{props.title}</h1>
                        <p>{props.text}</p>
                        <a href={props.url} className={props.btnClass}> {props.buttonText} </a>
                    </div>

                    <div className={props.picSize}>
                        <img alt='img' src={props.heroImg} />
                    </div>
                    
                </div>

            </div>
        </>
    )
}

export default Hero;