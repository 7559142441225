import "./styles.css"; // stil za pisanje
import Home from "./routes/Home";
import Solutions from "./routes/Solutions";
import Blog from "./routes/Blog";
import About from "./routes/About";
import Contact from "./routes/Contact";
import NoPage from "./routes/NoPage"
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/solutions" element={<Solutions/>}/>
          <Route path="/blog" element={<Blog/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="*" element={<NoPage/>}/>
        </Routes>
    </div>
  );
}
export default App;