
import Navbar from "../components/Navbar";
import Error404 from "../components/Error404";
import Footer from "../components/Footer";
import { useEffect } from "react";

function Blog (){
    useEffect(() => {
        document.title = "Dinarac | 404";
    },[]);
    return(
        <>
            <Navbar/>

            <Error404 />
            
            <Footer/>
        </>
    )
}

export default Blog;