import "./FeaturesStyles.css"
import pic41 from "../assets/feature_1.jpg"
import pic42 from "../assets/feature_2.jpg"
import pic43 from "../assets/feature_3.jpg"
import pic44 from "../assets/feature_4.jpg"

const Features = () => {
    return(
        <div className="features">
            <h2>Features</h2>

            <div className="feature1">
                <div className="text_features">
                    <h3>Customizable Solutions</h3>
                    <ul>
                        <li><b>Meshing Excellence</b>: Advanced meshing software to optimize simulation accuracy and efficiency, ensuring precise representation of complex geometries.</li>
                        <li><b>Parallel Computing</b>: Tailored software for parallel computing, leveraging the power of multiple processors to deliver faster simulation results and accelerated project timelines.</li>
                        <li><b>GPU Native Capabilities</b>: Utilizing the capabilities of graphic processing units, enabling rapid equation solving and significantly reducing simulation runtimes.</li>
                        <li><b>Specialized Physics Solvers</b>: Specialized software modules designed to address specific physics problems and phenomena, enhancing the fidelity and scope of simulations.</li>
                    </ul>
                </div>
                <div className="pic_features">
                    <img alt="image" src={pic41}/>
                    <img alt="image" src={pic42}/>
                </div>
            </div>

            <div className="feature2">
                <div className="pic_features">
                    <img alt="image" src={pic43}/>
                    <img alt="image" src={pic44}/>
                </div>
                <div className="text_features">
                <h3>Expert Consultant Services</h3>
                    <ul>
                        <li><b>Simulation Services</b>: On-demand CFD/FEM simulations for your unique project requirements, ensuring precise and reliable insights.</li>
                        <li><b>Computational Analysis</b>: Utilizing innovative computational techniques to study and optimize a wide range of engineering and scientific problems.</li>
                        <li><b>CFD/FEM Training and Courses</b>: Comprehensive training programs and courses for CFD and FEM simulations and analysis, empowering your team with invaluable expertise.</li>
                        <li><b>Performance Evaluation</b>: Detailed analysis of existing designs and processes, providing recommendations for improvements and enhanced performance.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Features