import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import hero_contact from "../assets/hero_contact.svg"
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import { useEffect } from "react";

function Contact (){
    useEffect(() => {
        document.title = "Dinarac | Contact";
    },[]);

    return(
        <>
            <Navbar/>
            <Hero
            cName='hero_small'
            heroImg={hero_contact}
            picSize='hero_pic_small'
            title="Contact us"
            btnClass="hide"
            />

            <ContactUs/>

            <CTA/>

            <Footer/>
        </>
    )
}

export default Contact;