import "./MisionVisionStyles.css"
import whoweare from "../assets/whoweare.svg"
import mission from "../assets/mission.svg"
import vision from "../assets/vision.svg"

const MisionVision = () => {
    return(
        <div className="mission_vision">
            <div className="mission_vision_item">
                <div className="picture_mision_vision">
                    <img alt='image' src={whoweare} />
                </div>
                <h3>Who We Are?</h3>
                <p>We are a team of CFD/FEA specialists, with over 5 years of exceptional experience in
                    software development. Our expertise extends to IT consulting, comprehensive support, and a diverse range of outsourcing
                    services, embodying technical excellence at every step of our journey.</p>
            </div>
            <div className="mission_vision_item">
                <div className="picture_mision_vision">
                    <img alt='image' src={mission} />
                </div>
                <h3>Mission</h3>
                <p>Our mission is to create custom software solutions that bring value to people and organizations and help them become
                    more efficient and successful.</p>
            </div>
            <div className="mission_vision_item">
                <div className="picture_mision_vision">
                    <img alt='image' src={vision} />
                </div>
                <h3>Vision</h3>
                <p>Our vision is to empowering global transformation through custom software solutions, driven by deep physics knowledge
                    and advanced CFD simulations, to revolutionize technology and change the world.</p>
            </div>
        </div>
    )
}

export default MisionVision