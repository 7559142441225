import "./BenefitsStyles.css"
import pic31 from "../assets/pic_3_1.svg"
import pic32 from "../assets/pic_3_2.svg"
import pic33 from "../assets/pic_3_3.svg"

const Benefits = () =>{
    return(
        <div className="benefits">
            <h2>Benefits of Dinarac</h2>
            <div className="pics_texts_benefits">

                <div className="pic_text_benefits">
                    <div className="picture_benefits">
                        <img alt='image' src={pic31} />
                    </div>
                    <div className="text_benefits">
                        <h3>Scientific Expertise</h3>
                        <p>Understanding the intricate science behind physics simulations sets us apart.
                            With a deep focus on equations, numerics, and code design, our team of experts ensures unparalleled accuracy and reliability
                            in every simulation, delivering results that drive success.</p>
                    </div>
                </div>

                <div className="pic_text_benefits">
                    <div className="picture_benefits">
                        <img alt='image' src={pic32} />
                    </div>
                    <div className="text_benefits">
                        <h3>Tailored Software</h3>
                        <p>Embracing the power of customization, we create bespoke software solutions precisely tailored to meet your unique needs.
                            Say goodbye to unnecessary features and complexities - our streamlined approach saves time and seamlessly integrates
                            into your existing infrastructure.</p>
                    </div>
                </div>

                <div className="pic_text_benefits">
                    <div className="picture_benefits">
                        <img alt='image' src={pic33} />
                    </div>
                    <div className="text_benefits">
                        <h3>Precision - Accuracy</h3>
                        <p>When precision is paramount, our simulations are finely tuned to focus solely on the specific values and outcomes you seek within the domain.
                            This targeted approach enables us to deliver highly accurate solutions, ensuring your engineering challenges
                            are solved with the utmost reliability and confidence.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Benefits