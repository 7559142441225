import { Button } from "bootstrap"
import "./CTAStyles.css"

const CTA = () => {
    return(
        <div className="cta">
            <h2>Contact Us</h2>
            <form action="https://formsubmit.co/f2ed5f93688f4e2d312f176e3e83eee5" method="POST">
                <div className="cta_name_email">
                    <div className="cta_input">
                        <label>Name:</label>
                        <input type="text" name="Name" placeholder="Name" required />
                    </div>
                    <div className="cta_input">
                        <label>Email:</label>
                        <input type="text" name="Email" placeholder="Email" required />
                    </div>
                </div>
                <label>Message:</label>
                <textarea name="Message" placeholder="Feel free to make an inquiry, submit a project proposal, send us a message, or secure a free initial consultation!" required />
                <button class="button_message">Send Message</button>
            </form>
        </div>
    )
}

export default CTA