import "./ProblemSolutionStyles.css"
import pic2 from "../assets/pic_2.svg"

const ProblemSolution = () =>{
    return(
        <div className="problem_solution">
            <h2>Challenges and Innovative Solutions</h2>
            <div className="pic_text_problem_solution">
                <div className="pic_problem_solution">
                    <img alt='image' src={pic2} />
                </div>
                <div className="texts_problem_solution">
                    <div className="text_problem_solution">
                        <h3>Understanding Your Product's Potential</h3>
                        <p>In today's competitive landscape, understanding your product's performance and identifying areas for improvement is
                        crucial for staying ahead of the curve. At Dinarac, our team of skilled engineers and experts begin by comprehensively
                        analyzing your product, assessing its strengths, weaknesses, and untapped possibilities.
                        </p>
                    </div>
                    <div className="text_problem_solution">
                        <h3>Harnessing the Power of Custom Simulations</h3>
                        <p>As your product undergoes simulation and calculation within our state-of-the-art virtual environment, we ensure that
                        every aspect is rigorously examined, from fluid dynamics to thermal, structural mechanics and beyond. Our advanced
                        Computational Fluid Dynamics (CFD) and Finite Element Method (FEM) simulations provide a deep understanding of your product's
                        behavior under various conditions, helping you fine-tune its design and performance for maximum efficiency and reliability.
                        </p>
                    </div>
                    <div className="text_problem_solution">
                        <h3>Advanced Solutions, Enhanced Products</h3>
                        <p>Through our custom simulations, your product's design is optimized with precision, resulting in improved performance,
                        increased efficiency, and superior durability. Embrace the confidence of knowing your product has been refined to surpass
                        industry standards, captivating your customers with its cutting-edge features and setting new benchmarks in excellence.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProblemSolution