import "./WorkflowStyles.css"
import pic41 from "../assets/workflow_1.jpg"
import pic42 from "../assets/workflow_2.jpg"
import pic43 from "../assets/workflow_3.jpg"
import pic44 from "../assets/workflow_4.jpg"
import pic45 from "../assets/workflow_5.jpg"

const Features = () => {
    return(
        <div className="workflow">
            <h2>Dinarac Workflow</h2>


            <div className="pic_text_workflow">
                <div className="text_workflow">
                    <h3>Code Design - Development</h3>
                    <p>At the core of our business lies the code design and development process. Our team of skilled programmers employs the most
                    cutting-edge numeric algorithms to craft bespoke solutions tailored to solve specific engineering challenges or provide comprehensive CFD services and support.
                    We strive to deliver the most optimal, efficient, and precise simulations, driving our clients towards their desired outcomes.
                    </p>
                </div>
                <div className="pic_workflow">
                    <img alt="image" src={pic42}/>
                </div>
            </div>

            <div className="pic_text_workflow">
                <div className="pic_workflow">
                    <img alt="image" src={pic43}/>
                </div>
                <div className="text_workflow">
                    <h3>Solver Creation - Validation</h3>
                    <p>With the code design in place, we bring our simulation to life through solver creation and rigorous validation. Iterating
                    through various steps, we fine-tune the simulation parameters, selecting the best-suited geometry, turbulence models, meshing techniques, and other essential CFD details.
                    This validation process ensures that our simulation accurately represents real-world scenarios, instilling confidence in our results.
                    </p>
                </div>
            </div>

            <div className="pic_text_workflow">
                <div className="text_workflow">
                    <h3>Analysis - Reporting</h3>
                    <p>As the simulation unfolds, we analyze the results, drawing valuable insights that lead to informed decisions.
                        Our expert team generates clear and easily understandable reports, presenting a extensive overview of the simulation outcomes.
                        Through concise and professional presentation, we equip our clients with the knowledge to make strategic choices, enabling them to optimize their designs and processes.
                    </p>
                </div>
                <div className="pic_workflow">
                    <img alt="image" src={pic44}/>
                </div>
            </div>

        </div>
    )
}

export default Features