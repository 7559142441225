export const MenuItems = [
    {
        title : 'Home',
        url : '/',
        cName : 'nav-links' //className
    },
    {
        title : 'Solutions',
        url : '/solutions',
        cName : 'nav-links' //className
    },
    {
        title : 'Blog',
        url : '/blog',
        cName : 'nav-links' //className
    },
    {
        title : 'About',
        url : '/about',
        cName : 'nav-links' //className
    },
    {
        title : 'CONTACT US',
        url : '/contact',
        cName : 'nav-links-contact' //className
    }
]