import "./Error404Styles.css"

const ContactUs = () => {
    return(
        <div className="error">
            <div className="error_item">
                <h3>404</h3>
                <h4>Page not found</h4>
                <p>The page you are looking for does not exist. It might have been moved or deleted.</p>
                <a href="/" className="back_to_home"> Back to Home </a>
            </div> 
        </div>
    )
}

export default ContactUs