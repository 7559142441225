import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import hero_solutions from "../assets/hero_solutions.svg"
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import Workflow from "../components/Workflow";
import { useEffect } from "react";

function Solutions (){
    useEffect(() => {
        document.title = "Dinarac | Solutions";
    },[]);
    return(
        <>
            <Navbar/>
            <Hero
            cName='hero_small'
            heroImg={hero_solutions}
            picSize='hero_pic_small'
            title="Custom solutions"
            btnClass="hide"
            />

            <Workflow/>
            
            <CTA/>

            <Footer/>
        </>
    )
}

export default Solutions;