import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import hero_home from '../assets/hero_home.svg';
import ProblemSolution from "../components/ProblemSolution";
import Benefits from "../components/Benefits";
import Applications from "../components/Applications";
import Features from "../components/Features";
import Partners from "../components/Partners";
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import { useEffect } from "react";

function Home (){
    useEffect(() => {
        document.title = "Dinarac | Home";
    },[]);
    return(
        <>
            <Navbar/>

            <Hero
            cName='hero'
            heroImg={hero_home}
            picSize='hero_pic_big'
            title="Tailored CFD/FEM Simulations"
            text="Empowering Your Business with Custom Software: Unleashing the Potential of Tailored Simulation Solutions and Advanced Engineering Tools."
            buttonText="LEARN MORE"
            url="/contact"
            btnClass="show"
            />
            
            <ProblemSolution/>

            <Benefits/>

            <Applications/>

            <Features/>

            <Partners/>

            <CTA/>

            <Footer/>
        </>
    )
}

export default Home;