import "./PartnersStyles.css"
import pic_jadran from "../assets/partners/jadran.svg"
import pic_nsd from "../assets/partners/nsd.svg"
import pic_gebrile from "../assets/partners/gebrile.svg"

const partners = () => {
    return(
        <div className="partners">
            <h2>Partners</h2>
            <div className="partners_all">

                <div className="partner">

                    <div className="pic_partner">
                        <img alt="image" src={pic_jadran} />
                    </div>

                    <div className="text_partner">
                        <p>With the help of Dinarac's advanced FEM analysis, we achieved remarkable results in our welding process.
                            Their simulation tool enabled us to increase productivity significantly and optimize our manufacturing operations like never before.
                        </p>
                    </div>

                    <div className="author_partner">
                        <div className="name_partner">
                            <p>CEO Darko Danilovic, Jadran</p>
                        </div>
                    </div>

                </div>

                <div className="partner">

                    <div className="pic_partner">
                        <img alt="image" src={pic_nsd} />
                    </div>

                    <div className="text_partner">
                        <p>Working with Dinarac on our complex cooling system for electronics was a game-changer.
                            Their in-depth understanding of CFD and tailored software solutions allowed us to design and optimize intricate enclosures for large electronics boards.
                            Thanks to their professional approach, we've achieved exceptional thermal performance and customer satisfaction.
                        </p>
                    </div>

                    <div className="author_partner">
                
                        <div className="name_partner">
                            <p>CEO Nebojsa Jankovic, NSD</p>
                        </div>
                    </div>

                </div>

                <div className="partner">
                    
                    <div className="pic_partner">
                        <img alt="image" src={pic_gebrile} />
                    </div>
                    <div className="text_partner">
                        <p>Your Knowledge Base is great!
                            Dinarac has been instrumental in solving our fume extraction challenges.
                            We wholeheartedly recommend their services.
                        </p>
                    </div>

                    <div className="author_partner">
                        <div className="name_partner">
                            <p>Dr. Ing. Andrija Veselinovic, Gebrile</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default partners