import "./ContactUsStyles.css"

const ContactUs = () => {
    return(
        <div className="contact_us">
            <div className="contact_us_text">
                <h2>Get in Touch</h2>
                <p>Connect with us today and embark on a transformative journey towards engineering brilliance.
                Whether you're ready to take the next big step or simply seeking answers to your questions, we're here to guide you every step of the way.
                Embrace innovation, unlock potential, and experience the power of precision simulations with Dinarac. The <b>initial consultation</b> is
                offered at <b>no cost</b>, providing an opportunity to explore and assess our collaboration.</p>
            </div>
        </div>
    )
}

export default ContactUs