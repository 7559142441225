import { Component } from 'react';
import './NavbarStyles.css';
import { MenuItems } from './MenuItems';
import logo from '../assets/LOGO_dinarac.svg';

function myFunction() {
    window.location.reload();
}


class Navbar extends Component {
    state = {clicked: false}; //pocetna vrednost klika je false, pa kad se klikne da umesto menija pojavi se X

    componentDidMount() { //ovo je za drzanje aktivnog hovera kad smo na toj lokaciji
        const pathname = window.location.pathname;
        const links = document.querySelectorAll('.nav_menu a');
        links.forEach(link => {
            if (link.getAttribute('href') === pathname) {
                link.classList.add('active');
            }
        });
    }


    handleClick = () =>{
        this.setState({clicked: !this.state.clicked})
    }
    render(){
        return(
            <nav className='navbar'>
                <a href='/' onclick='myFunction()'><img src={logo} className='Logo'/></a>
                <div className='menu_icons' onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>

                <ul className={this.state.clicked ? 'nav_menu active' : 'nav_menu'}>
                    {MenuItems.map((item, index) =>{
                        return(
                            <li key={index}>
                                <a className={item.cName} href={item.url}>{item.title}</a>
                            </li>
                        )
                    })}
                </ul>

            </nav>
        )
    }
}

export default Navbar